import Image from 'next/image'
import { useEffect, useState } from 'react'
import useBreakpoints from 'src/hooks/useBreakpoints'
import Whatsapp from '../../public/svgs/whatsapp-icon.svg'

type IProps = {
  mobileBottom?: string
}

const WhatsappButton = ({ mobileBottom }: IProps) => {
  const { isUpMd } = useBreakpoints()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop
      setIsVisible(scrollY > 20)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      className={`${mobileBottom ? mobileBottom : 'bottom-4'} right-4 z-10 md:bottom-8 md:right-8 ${
        isVisible || isUpMd ? 'fixed' : 'hidden'
      } transition-opacity duration-300`}
    >
      <div
        className={`'md:w-fit flex cursor-pointer items-center space-x-2 rounded-full bg-green-500 p-3 transition-all duration-300`}
      >
        <a
          className={`inline overflow-hidden text-[14px] font-semibold text-white-500 transition-all duration-300 md:w-fit`}
          href="https://api.whatsapp.com/send?phone=525559301159"
          target="_blank"
        >
          <Image width={30} height={30} src={Whatsapp} alt="whatsapp" />
        </a>
      </div>
    </div>
  )
}

export default WhatsappButton
